import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Spinner } from 'flowbite-react';
import { HTTPError } from 'ky';
import { Helmet } from 'react-helmet';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { HiHome } from 'react-icons/hi';
import { toast } from 'react-toastify';
import type { Analytic } from 'types/analytic';
import { type GsErrors } from 'types/gs_errors';
import { type SearchConsole } from 'types/search_console';
import { getSearchConsole } from 'domains/api/getSearchConsole';
import { postSearchConsole } from 'domains/api/postSearchConsole';
import { usePageTracking } from 'utils/ga';
import FormInput from 'components/atoms/FormInput';

type SearchConsoleData = {
  siteUrl: string;
};
const SearchConsolePage: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  const analytic = useLoaderData() as Analytic;
  const { analyticId } = useParams();
  const [searchConsole, setSearchConsole] = useState<SearchConsole | undefined>(
    undefined,
  );
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      if (analyticId != null) {
        const searchConsoleResponse = await getSearchConsole(analyticId);
        setSearchConsole(searchConsoleResponse.searchConsole);
      }
      setInitialLoading(false);
    })();
  }, [analyticId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchConsoleData>({
    defaultValues: {
      siteUrl: '',
    },
  });

  const onSubmit: SubmitHandler<SearchConsoleData> = async (
    data: SearchConsoleData,
  ) => {
    try {
      setLoading(true);
      await postSearchConsole(analytic.id, data);
      setLoading(false);
      navigate(0);
    } catch (error: unknown) {
      if (error instanceof HTTPError) {
        const response = (await error.response.json()) as GsErrors;
        response.errors.forEach((errorMsg: string) => toast.error(errorMsg));
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Search Console 設定: Growth Support</title>
      </Helmet>
      {initialLoading ? (
        <div className="text-center mt-40">
          <Spinner color="success" aria-label="loading spinner" size="xl" />
        </div>
      ) : (
        <>
          <div className="border-b py-3 pl-2 flex justify-between">
            <Breadcrumb aria-label="Default breadcrumb example">
              <Breadcrumb.Item href={`/analytics/${analyticId}`} icon={HiHome}>
                {analytic.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item>Search Console</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="border-b pt-5 pb-2 pl-2 flex justify-between">
            <h1 className="text-xl leading-none tracking-tight text-gray-900 md:text-xl dark:text-white">
              Search Consoleの設定
            </h1>
          </div>
          <div className="my-4 mx-3">
            <div className="border-b py-3 pl-2">
              {searchConsole ? (
                <h1 className="mb-2 text-lg leading-none tracking-tight text-gray-900 md:text-lg dark:text-white">
                  {searchConsole.siteUrl} (登録済み)
                </h1>
              ) : (
                <>
                  <p className="text-gray-700">
                    登録するとSearch Console
                    のデータを使ったレポートを作成できます
                  </p>
                  <p className="mb-1 text-lg leading-relaxed text-gray-500 dark:text-gray-400">
                    以下のブログ記事のステップに従い、Search Console のサイト
                    URL を登録してください
                    <br />
                    TODO: あとでブログ書いてリンクを更新する
                  </p>
                  <a
                    href="https://growth-support.hatenablog.com/entry/2024/04/20/084916"
                    target="_blank"
                    rel="noreferrer"
                    className="bg-transparent text-blue-500 font-semibold py-2 px-4 border border-blue-400 rounded inline-block"
                  >
                    Search Console 登録手順
                  </a>

                  <form
                    className="max-w-xl pl-8"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <FormInput
                      label="サイトURL"
                      attribute="siteUrl"
                      register={register('siteUrl', { required: true })}
                      errors={errors}
                      required={true}
                    />
                    <Button
                      type={'submit'}
                      color={'gray'}
                      isProcessing={loading}
                    >
                      登録する
                    </Button>
                  </form>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SearchConsolePage;
